<template>
  <div>
    <gmap-map
        :zoom="14"    
        :center="center"
        style="width:100%;  height: 600px;"
        
        :options="{
          zoomControl: true,
          mapTypeControl: false,
          backgroundColor:'#000000',
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          styles: {
            default: [],
            hide: [
              {
                featureType: 'poi.attraction',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }, 
          disableDefaultUi: false
        }"
      >
      <gmap-marker
        :key="index"
        v-for="(m, index) in locationMarkers"
        :position="m.position"
        :title="m.title"
         @click="toggleInfoWindow(m,index)"
      ></gmap-marker>
      <gmap-info-window 
        :options="infoOptions" 
        :position="infoWindowPos" 
        :opened="infoWinOpen" 
        @closeclick="infoWinOpen=false">
        {{infoContent}}
      </gmap-info-window>
    </gmap-map>
  </div>
</template>
<script>
import axios from 'axios'
import api from '@/http'
export default {

  name:"Mapa"
  ,data:()=>({
   center: { 
      lat: 39.7837304,
      lng: -100.4458825
    },
    locationMarkers: [],
    locPlaces: [],
    existingPlace: null,
    usu:null,
    ultima_posicao_banco:null,
    infoContent: '',
    infoWindowPos: null,
    infoWinOpen: false,
    currentMidx: null,
    //optional: offset infowindow so it visually sits nicely on top of our marker
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -35
      }
    },
  }),
  methods:{
    async pega_end(url){
      return new Promise(()=>{
        axios(url)
        .then(res=>{
          console.log(res.data)
        })
      })
    },
    initMarker(loc) {
      this.existingPlace = loc;
    },
    addLocationMarker() {
      setInterval(()=>{
        const marker = this.center
        this.locationMarkers.push({ position: marker,title:this.usu.usuario.data.text});
        this.locPlaces.push(this.existingPlace);
        this.center = marker;
        this.existingPlace = null;
      },100)
      
    },
    locateGeoLocation() {
      navigator.geolocation.getCurrentPosition(res => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };

      });
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = marker.title;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;

      }
    },
    async insert(obj){
      return new Promise(()=>{
        api({
          url:`mapa/localizacao/`,
          method:'POST',
          data:obj
        }).then(res=>{
          if(res.data.length){
            console.log('criou o insert')
          }
        })
      })
    },
    async verifica(usuario){
      return new Promise(()=>{
        api({
          url:`mapa/localizacao/?usuario=${usuario}`
        }).then(res=>{
          if(res.data.length==0){
            console.log('chama o insert')
            this.insert(this.center)
          }else{
            this.ultima_posicao_banco = res.data[0]
          }
        })
      })
    },
    async update(usuario,obj){
      return new Promise(()=>{
        api({
          url:`mapa/update-localizacao/${usuario}/`,
          method:'PUT',
          data:obj
        }).then(res=>{
          console.log('atualizado',res.data)
        })
      })
    },
    atualizaPosicao(){
      this.update(this.usu.usuario.data.id, this.center)      
    }
  },
  mounted(){
   
    this.locateGeoLocation();
    this.addLocationMarker();
    this.usu = JSON.parse(localStorage.getItem('cfgu'))
    this.verifica(this.usu.usuario.data.id)
    this.atualizaPosicao()
    setInterval(()=>{
      this.atualizaPosicao()
    },15000)
  }
}
</script>